import authHelper from './helpers/authHelper';
import { axiosErrorHandler, axiosResponseHandler, callTheBaseServerGet, } from './helpers/axiosHelper';

import { byId } from './helpers/utility';

authHelper.validate().then(async () => { 


    const total_users_count = byId('total_users_count');
    //    let online_users_count = byId('online_users_count');
    //    let pending_withdrawals_count = byId('pending_withdrawals_count');
    //    let wallet_balance = byId('wallet_balance');



    const dashboardPageFun = {
        fetch: {
            dashboardData: async () => {
                try {

                    const response = await callTheBaseServerGet('/basic/getDashboardData');
                    axiosResponseHandler(response);
                    const res = response.data;
                    if (res.status === 'success') {
                        console.log(res);
                        const { allUsersCount } = res.dashboardData;
                        total_users_count.innerHTML = allUsersCount;

                    }


                } catch (error) {
                    axiosErrorHandler(error);
                }
            }
        }
    };
    window.dashboardPageFun = dashboardPageFun;

    dashboardPageFun.fetch.dashboardData();




});













